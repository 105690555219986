import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../../store";
import * as ParcelStore from "../../../../store/parcel";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { IParcel } from "../../../../models/IParcel";
import { InputSwitch } from "primereact/inputswitch";
import { AccountType } from "../../../../models/enums/accountType";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useCallback, useEffect, useRef, useState } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";

import { loadStripe, Stripe, StripeIbanElement, TokenResult } from "@stripe/stripe-js";
import { IUser } from "../../../../models/IUser";
import { getDefaultSettings } from "http2";
import { PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link';

type ParcelDetailsPayoffRequestProps = ParcelStore.ParcelState & typeof ParcelStore.actionCreators & RouteComponentProps<{}>;

function ParcelDetailsPayoffRequest(props: ParcelDetailsPayoffRequestProps) {
  const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);
  const [selectedParcelId, setSelectedParcelId] = useState(props.selectedParcel?.parcelID);
  const [relatedTaxCertificateMenuItems, setRelatedTaxCertificateMenuItems] = useState();
  const [payoffData, setPayoffData] = useState([{ type: "Principal", amount: 0 }]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [expandedRows, setExpandedRows] = useState({});
  const [displayPaymentMethods, setDisplayPaymentMethods] = useState(false);
  const [displayPaymentPlanMethods, setDisplayPaymentPlanMethods] = useState(false);
  const [displayACHRegister, setDisplayACHRegister] = useState(false);
  const [displayACHRegisterSuccess, setDisplayACHRegisterSuccess] = useState(false);
  const [displayACHValidate, setDisplayACHValidate] = useState(false);
  const [displayACHValidateSuccess, setDisplayACHValidateSuccess] = useState(false);
  const [displayACHPayment, setDisplayACHPayment] = useState(false);
  const [displayACHPaymentSuccess, setDisplayACHPaymentSuccess] = useState(false);
  const [displayRecurringPayment, setDisplayRecurringPayment] = useState(false);
  const [displayRecurringPaymentSuccess, setDisplayRecurringPaymentSuccess] = useState(false);
  const [partialPaymentValue, setPartialPaymentValue] = useState(0);
  const [displayLoginMessage, setDisplayLoginMessage] = useState(false);
  const [displaySendToOldestUnpaid, setDisplaySendToOldestUnpaid] = useState(false);
  const [isPartialPayment, setIsPartialPayment] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(0);
  const [currentUser, setCurrentUser] = useState({} as IUser);
  const [numberOfMonths, setNumberOfMonths] = useState(12);

  const [achValidationAmount1, setACHValidationAmount1] = useState(0);
  const [achValidationAmount2, setACHValidationAmount2] = useState(0);
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountholderName, setAccountholderName] = useState("");
  const [accountholderType, setAccountholderType] = useState("individual");
  const [accountType, setAccountType] = useState("checking");
  const [showACH, setShowACH] = useState(false);
  const [plaidToken, setPlaidToken] = useState<string | null>(null);
  const [defaultAchAccountId, setDefaultAchAccountId] = useState(0);

  const dt = useRef<DataTable<any[]>>(null);
  let items: any;
  let stripe: Stripe | null;
  const menu: any = useRef(null);

  const accountHolderTypes = [
    { label: "Individual", value: "individual" },
    { label: "Company", value: "company" },
  ];

  const accountTypes = [
    { label: "Checking ", value: "checking" },
    { label: "Savings", value: "savings" },
  ];

  //loadStripe("pk_test_51JgqYlFEm65HGDLi8BwyTu1rYrUTmtpH5lwD0wg6HCJK601Z7DxYCXhoN5HmslzmFrYnEYPnOIMEmlw7raybEX6a00wzgjF65O")
  loadStripe("pk_live_51JgqYlFEm65HGDLi0x9ArHCzMUbIyCJkNUYeAvMmbePCtqb1mVhiTTej2w6uFeKs1VZFroI9JHuJuR7NZm02qI0v00jMU4N3rw")
    .then(function (result: Stripe | null) {
      stripe = result;
    })
    .catch(function (error) {
      alert(error);
    });

  useEffect(() => {
    createLinkToken();
  }, []);

  const createLinkToken = async () => {
    try {
      const response = await fetch('/api/payments/create_plaid_link_token', {
        method: 'POST',
        headers: new Headers({
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }),
      });
      const { link_token } = await response.json();
      setPlaidToken(link_token);
    }
    catch (e) {

    }
  };

  const onSuccess = useCallback<PlaidLinkOnSuccess>((publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    const createLinkToken = async (accountId: string, last4: string, bankName: string) => {
      console.log("doing the stuff now");
      const response = await fetch(`/api/payments/create_plaid_stripe_token?token=${publicToken}&accountId=${accountId}`, {
        method: 'POST',
        headers: new Headers({
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }),
      });
      const { stripe_bank_account_token, request_id } = await response.json();

      fetch(`api/payments/ach`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ last4: last4, institution: bankName, token: { id: stripe_bank_account_token, bank_account: { account_holder_name: "Test User" } } })
      }).then((response) => {
        return response.json() as Promise<any>;
      }).then((customer) => {
        props.getCurrentUser(currentUserId);
        setDisplayACHPayment(true);
        //props.history.push(`/parcels/${selectedParcelId}/paymentprocesssuccess`);
      });
    };
    console.log(metadata);

    metadata.accounts.forEach(account => {
      console.log(account);
      createLinkToken(account.id, account.mask, metadata.institution?.name ?? "");
    });


  }, []);

  const { open, ready } = usePlaidLink({
    token: plaidToken,
    onSuccess,
    // onEvent
    // onExit
  });





  useEffect(() => {
    setSelectedParcel(props.selectedParcel);
    setPayoffData([]);
    setPayoffData([
      { type: "Principal", amount: selectedParcel?.payofF_PRINCIPLE ?? 0 },
      { type: "Interest", amount: selectedParcel?.payofF_INTEREST ?? 0 },
      { type: "Expenses", amount: selectedParcel?.payofF_EXPENSE ?? 0 },
      { type: "Total Payoff", amount: selectedParcel?.payofF_TOTAL ?? 0 },
    ]);
    items = props.selectedParcel?.relatedTaxCertificates.map(function (object, i) {
      return {
        label: object.taxSaleNo,
        icon: "pi pi-reply",
        command: () => {
          setSelectedParcelId(object.parcelID);
          props.getParcel(object.parcelID);
        },
      };
    });
    setRelatedTaxCertificateMenuItems(items);
    setPartialPaymentValue(props.selectedParcel?.mthPayTotal ?? 0);
    setNumberOfMonths(props.selectedParcel?.ppsTerm ?? 0);
    if ((props.selectedParcel?.mthPayTotal ?? 0) > 0) {
      setIsPartialPayment(true);
    }
  }, [props.selectedParcel]);

  useEffect(() => {
    setPayoffData([]);
    setPayoffData([
      { type: "Principal", amount: selectedParcel?.payofF_PRINCIPLE ?? 0 },
      { type: "Interest", amount: selectedParcel?.payofF_INTEREST ?? 0 },
      { type: "Expenses", amount: selectedParcel?.payofF_EXPENSE ?? 0 },
      { type: "Total Payoff", amount: selectedParcel?.payofF_TOTAL ?? 0 },
    ]);


    if (!isPartialPayment) {
      if ((selectedParcel?.payofF_TOTAL ?? 0) > 24752) {
        setShowACH(false);
        console.log(selectedParcel?.payofF_TOTAL);
        console.log((selectedParcel?.payofF_TOTAL ?? 0) > 24752);
      }
    }
  }, [selectedParcel]);

  useEffect(() => {
    setCurrentUser(props.currentUser);
    if (props.currentUser.stripeCustomers !== undefined) {
      props.currentUser.stripeCustomers.forEach(element => {
        if (element.isDefaultAccount) {
          setDefaultAchAccountId(element.id);
        }
      });
    }
  }, [props.currentUser]);

  useEffect(() => {
    const selectedParcelId = (props.match.params as any).parcelId;
    setSelectedParcelId(selectedParcelId);
    props.getParcel(selectedParcelId);
    props.logPayoffView(selectedParcelId);
    if (localStorage.getItem("token")) {
      setCurrentUserId((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
      props.getCurrentUser((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
    }
    //createLinkToken();
  }, []);

  const notify = () => {
    toast.success("Settings Saved!");
  };



  const renderFooter = () => {
    return (
      <div>
        <Button label="No" icon="pi pi-times" onClick={() => setDisplaySendToOldestUnpaid(false)} className="p-button-text" />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => {
            setSelectedParcelId(selectedParcel?.oldestRelatedUnpaidCertificateId);
            props.getParcel(selectedParcel?.oldestRelatedUnpaidCertificateId ?? 0);
            props.history.push(`/parcels/${selectedParcel?.oldestRelatedUnpaidCertificateId}/payoffrequest`);
            setDisplaySendToOldestUnpaid(false);
          }}
          autoFocus
        />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div>
        <span className="pi pi-exclamation-triangle warning"></span>
        <span>NOTICE!</span>
      </div>
    );
  };

  const formatCurrency = (value: number | null) => {
    if (value == null) {
      return "$0";
    }
    return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
  };

  const leftContents = (
    <React.Fragment>
      <Button
        icon="pi pi-arrow-left"
        className="p-mr-2"
        label={`Back to Certificate ${selectedParcel?.taxSaleNo}`}
        onClick={() => {
          props.history.push(`/parcels/${selectedParcel?.parcelID}`);
        }}
      />
      {/* {(selectedParcel?.relatedTaxCertificates.length ?? 0) > 0 && (
        <div>
          <Menu model={relatedTaxCertificateMenuItems} popup ref={menu} />
          <Button
            label="View Related Tax Certificates"
            className="p-button-secondary"
            icon="pi pi-chevron-down"
            onClick={(event) => menu.current.toggle(event)}
          />
        </div>
      )} */}
    </React.Fragment>
  );

  const ACHHeader = (
    <div>
      Make an ACH Payment using your linked bank account on file. <br />
      {currentUser.stripeCustomers !== undefined && currentUser.stripeCustomers.map(account => {
        return <React.Fragment><Button
          icon={defaultAchAccountId == account.id ? "pi pi-check" : ""}
          className={defaultAchAccountId == account.id ? "p-button-success p-mr-2" : "p-button-outlined p-button-secondary p-mr-2"}
          iconPos="left"
          label={`${account.institution} account ending in ${account.achLast4}`}
          style={{ width: "100%", marginTop: "10px" }}
          onClick={() => {
            setDefaultAchAccountId(account.id);
          }}
        >
        </Button><br /></React.Fragment>
      })}
      <Button label="Remove all and register a different account" icon="pi pi-refresh" className="p-mr-2  p-button-danger p-button-text p-button-sm" style={{ width: "100%" }} onClick={() => {
        props.clearSavedACH(currentUserId);
        setDisplayPaymentMethods(false);
        open();
      }} />
      <br /><br />
      <div>By clicking "Submit Payment" you agree to have {(isPartialPayment ? formatCurrency((partialPaymentValue ?? 0) + (partialPaymentValue ?? 0) * 0.01) : formatCurrency((selectedParcel?.payofF_TOTAL ?? 0) + (selectedParcel?.payofF_TOTAL ?? 0) * 0.01))} deducted from your selected account.</div>
    </div>
  );

  const rightContents = (
    <React.Fragment>
      {selectedParcel?.canRequestPayoff === true &&
        selectedParcel?.moneyInEscrow === 0 &&
        (selectedParcel?.payofF_TOTAL ?? 0) > 0 &&
        selectedParcel?.sheriffSaleDate === null &&
        (selectedParcel?.hasPPS ?? 0) == 0 &&
        selectedParcel.isOldestUnpaidCertificate
        && (!(selectedParcel?.sentLegalFile && selectedParcel.noPayoffAfterLegal))
        && (
          <Button label="Make a Payment" icon="pi pi-dollar" className="p-mr-2  p-button-success" onClick={() => setDisplayPaymentMethods(true)} />
        )}
      {(selectedParcel?.payofF_TOTAL ?? 0) > 0 &&
        selectedParcel?.moneyInEscrow === 0 &&
        selectedParcel?.sheriffSaleDate === null &&
        (selectedParcel?.hasPPS ?? 0) == 0 &&
        !selectedParcel.isOldestUnpaidCertificate && (
          <Button
            label="Make a Payment"
            icon="pi pi-dollar"
            className="p-mr-2  p-button-success"
            onClick={() => {
              setDisplaySendToOldestUnpaid(true);
            }}
          />
        )}
      {(selectedParcel?.payofF_TOTAL ?? 0) > 0 && (selectedParcel?.hasPPS ?? 0) > 0 && currentUserId > 0 && (
        <React.Fragment>
          <Button
            label="Make a Monthly Payment"
            icon="pi pi-dollar"
            className="p-mr-2  p-button-success"
            onClick={() => {
              if (currentUser.username == "") {
                setDisplayLoginMessage(true);
              } else {
                setDisplayPaymentPlanMethods(true);
              }
            }}
          />
          {/* <Button
            label="Set Up Automatic Payments"
            icon="pi pi-sync"
            className="p-mr-2  p-button-success"
            onClick={() => {
              if (currentUser.username == "") {
                setDisplayLoginMessage(true);
              } else {
                setDisplayRecurringPayment(true);
              }
            }}
          /> */}
        </React.Fragment>
      )}
    </React.Fragment>
  );

  const rowExpansionTemplate: any = (data: any) => {
    return (
      <div style={{ marginLeft: "100px" }}>
        <div className="p-grid">
          <div className="p-col-4">
            <label style={{ fontWeight: "bold", fontSize: "large", marginBottom: "4px" }}>Description</label>
            <br />
            {selectedParcel?.subsequentData.map((item) => {
              return (
                <div className="truncate" title={item.subsequentName}>
                  {item.subsequentName}
                  <br />
                </div>
              );
            })}
            <label style={{ fontWeight: "bold", marginTop: "4px" }}>Total</label>
          </div>
          <div className="p-col-2" style={{ textAlign: "right" }}>
            <label style={{ fontWeight: "bold", fontSize: "large", marginBottom: "4px" }}>Amount</label>
            <br />
            {selectedParcel?.subsequentData.map((item) => {
              return (
                <div>
                  {formatCurrency(item.subsequentAmt as number)}
                  <br />
                </div>
              );
            })}
            <label style={{ fontWeight: "bold", marginTop: "4px" }}>{formatCurrency(selectedParcel?.payofF_PRINCIPLE as number)}</label>
          </div>
        </div>
      </div>
    );
  };

  const formatDate: any = (date: Date) => {
    let value = new Date(date);

    return value.toLocaleString().split(",")[0];
  };

  const paginatorLeft = <Button icon="pi pi-refresh" />;

  const header = (
    <div style={{ textAlign: "left" }}>
      <div className="p-grid">
        <div className="p-col-10">
          <h4>Payoff Breakdown</h4>
          {(selectedParcel?.noiFiledDate === null && selectedParcel?.reportOfficeOhio === true) && (<span>Good Until: {formatDate(new Date())}</span>)}
          {(selectedParcel?.noiFiledDate !== null || selectedParcel?.reportOfficeOhio !== true) && (<span>Good Until: {formatDate(selectedParcel?.payofF_GOOD_THRU)}</span>)}
        </div>
        <div className="p-col">
          <Button
            label="Print payoff"
            icon="pi pi-print"
            className="p-mr-2  p-button-success"
            style={{ marginTop: "12px", marginLeft: "40px" }}
            onClick={() => props.history.push(`/parcels/${selectedParcel?.parcelID}/paymentplanrequestprint`)}
          />
        </div>
      </div>
    </div>
  );

  const handleRowClick: any = (e: any) => {
    if (e.data.type === "Principal") {
      const data = {
        Principal: true,
      };
      setExpandedRows(data);
    }
  };

  const rowClass: any = (rowData: any) => {
    return {
      noExpander: rowData.type !== "Principal",
    };
  };

  const priceBodyTemplate = (rowData: any) => {
    return formatCurrency(rowData.amount as number);
  };

  return (
    <React.Fragment>
      <div>
        <Toolbar left={leftContents} right={rightContents} />
      </div>
      <br />
      {(selectedParcel?.relatedTaxCertificates.length ?? 0) > 0 && (
        <React.Fragment>
          <div className="p-grid">
            <div className="p-col">
              <Card title={`Related Tax Certificates`}>
                {props.selectedParcel?.relatedTaxCertificates.map((item: any) => {
                  return (
                    <React.Fragment>
                      <Button
                        className={`p-mr-2  ${item.status == "Redeemed" ? "p-button-success" : "p-button-outlined"}`}
                        label={`${item.taxSaleNo} - ${item.status}`}
                        icon="pi pi-reply"
                        onClick={() => {
                          setSelectedParcelId(item.parcelID);
                          props.getParcel(item.parcelID);
                          props.history.push(`/parcels/${item.parcelID}/payoffrequest`);
                        }}
                      />
                    </React.Fragment>
                  );
                })}
              </Card>
            </div>
          </div>
          <br />
        </React.Fragment>
      )}

      {((selectedParcel?.paymentPending != null && selectedParcel.paymentPending) || (selectedParcel?.redemptionInProcDate != null)) && selectedParcel.rtReceivedDate == null && (
        <div>
          <div className="p-message p-component p-message-success p-message-enter-done">
            <div className="p-m-2">
              <br />
              <h4>Payment has been submitted and is awaiting processing. </h4>
              <br />
            </div>
          </div>
        </div>
      )}
      {selectedParcel?.hasPPS && selectedParcel?.payPlanStatus != "DEFAULT" && currentUserId === 0 && (
        <React.Fragment>
          <div className="p-grid">
            <div className="p-col">
              <Card title={`ATTENTION`} className={"red-text"}>
                Our records indicate tax certificate {selectedParcel.taxSaleNo} is in an active payment plan. You can click{" "}
                <a href={`/login?parcelId=${selectedParcelId}`}>here</a> to login and make a payment on this plan. If you would like to know the balance due or
                have additional questions regarding your payment plan, please contact us at <a href="mailto:servicing@usassets.net">servicing@usassets.net</a>{" "}
                or 1-844-216-4341.
              </Card>
            </div>
          </div>
          <br />
        </React.Fragment>
      )}
      {selectedParcel?.hasPPS && currentUserId !== 0 && (
        <React.Fragment>
          <div className="p-grid">
            <div className="p-col">
              <Card title={`ATTENTION`} className={"red-text"}>
                Our records indicate tax certificate {selectedParcel.taxSaleNo} is in an active payment plan. You can click{" "}
                <span className="green-text">"Make a Monthly Payment"</span> above to make a payment on this plan. If you would like to know the balance due or
                have additional questions regarding your payment plan, please contact us at <a href="mailto:servicing@usassets.net">servicing@usassets.net</a>{" "}
                or 1-844-216-4341.
              </Card>
            </div>
          </div>
          <br />
        </React.Fragment>
      )}
      {((selectedParcel?.statusID == 23 || (selectedParcel?.hasPPS && selectedParcel?.payPlanStatus === "DEFAULT") || selectedParcel?.moneyInEscrow !== 0) && !selectedParcel?.activePPS) && (
        <React.Fragment>
          <div className="p-grid">
            <div className="p-col">
              <Card title={`ATTENTION`} className={"red-text"}>
                Tax Certificate {selectedParcel?.taxSaleNo} requires a payoff to be obtained directly from Adair Asset Management's Staff. To obtain the payoff
                please contact us as <a href="mailto:servicing@usassets.net">servicing@usassets.net</a> or 1-844-216-4341.
              </Card>
            </div>
          </div>
          <br />
        </React.Fragment>
      )}

      {(selectedParcel?.sentLegalFile && selectedParcel.noPayoffAfterLegal && !!!selectedParcel.hasPPS)
        && (
          <React.Fragment>
            <div className="p-grid">
              <div className="p-col">
                <Card title={`ATTENTION`} className={"red-text"}>
                  Our records indicate that parcel number {selectedParcel.parcelNo} is in a legal status. For information regarding the payoff amount or payment plan options, please contact our Servicing Department via email at  <a href="mailto:servicing@usassets.net">servicing@usassets.net</a> or by calling 1-844-216-4341.
                </Card>
              </div>
            </div>
            <br />
          </React.Fragment>
        )}

      {((selectedParcel?.sheriffSaleDate && new Date(selectedParcel?.sheriffSaleDate) > new Date()) ||
        (selectedParcel?.sheriffSaleDate && selectedParcel.mustGetPayoffFromCountyIfSheriffSaleDate))
        && (
          <React.Fragment>
            <div className="p-grid">
              <div className="p-col">
                <Card title={``}>
                  Our records indicate parcel number {selectedParcel.parcelNo} has been scheduled for sheriff sale via case number {selectedParcel.caseNum}. In
                  order to redeem the taxes for this parcel at this stage of the process, you must also pay current taxes and court costs directly to the Summit
                  County Fiscal Office. Please contact the Summit County Fiscal Office directly at 1-330-643-2600 for all payoff amounts and due dates.
                </Card>
              </div>
            </div>
            <br />
          </React.Fragment>
        )}
      {selectedParcel?.sheriffSaleDate && new Date(selectedParcel?.sheriffSaleDate) < new Date() && !selectedParcel?.mustGetPayoffFromCountyIfSheriffSaleDate && (
        <React.Fragment>
          <div className="p-grid">
            <div className="p-col">
              <Card title={``}>
                Our records indicate parcel number {selectedParcel.parcelNo} has gone through sheriff sale via case number {selectedParcel.caseNum} and may be
                past the stage where taxes can be redeemed. Please contact us directly with any inquiries at{" "}
                <a href="mailto:servicing@usassets.net">servicing@usassets.net</a> or 1-844-216-4341.
              </Card>
            </div>
          </div>
          <br />
        </React.Fragment>
      )}
      <div className="p-grid">
        <div className="p-col">
          <Card title={`Tax Certificate ${selectedParcel?.taxSaleNo}`} subTitle={`Parcel Number ${selectedParcel?.parcelNo}`} style={{ height: "524px" }}>
            <label className="field-label">
              <i className="pi pi-user"></i>&nbsp; Owner:
            </label>
            <br /> {selectedParcel?.ownerName}
            <br />
            <br />
            <label className="field-label">
              <i className="pi pi-map"></i>&nbsp; Property Address:
            </label>
            <br />
            {selectedParcel?.propertyAddress}
            <br />
            {selectedParcel?.propertyCity}, {selectedParcel?.stateName}, {selectedParcel?.propertyZip}
            <br />
            <br />
            <label className="field-label">
              <i className="pi pi-calendar"></i>&nbsp; Purchase Date:
            </label>
            <br />
            {new Date(selectedParcel?.purchaseDate ?? "1/1/1970").toLocaleString().split(",")[0]}
            <br />
            <br />
            {selectedParcel?.caseNum != undefined && (
              <div>
                <label className="field-label">
                  <i className="pi pi-briefcase"></i>&nbsp; Case Number:{" "}
                </label>
                <br />
                <span>{selectedParcel?.caseNum}</span>
                <br />
                <br />
              </div>
            )}
            {selectedParcel?.claimNum != undefined && (
              <div>
                <label className="field-label">
                  <i className="pi pi-briefcase"></i>&nbsp;Bankruptcy Case Number:{" "}
                </label>
                <br />
                <span>{selectedParcel?.claimNum}</span>
                <br />
                <br />
              </div>
            )}
          </Card>
        </div>
        <div className="p-col">
          <Card
            title={`${selectedParcel?.propertyAddress}`}
            subTitle={`${selectedParcel?.propertyCity}, ${selectedParcel?.stateName}, ${selectedParcel?.propertyZip}`}
            style={{ height: "524px" }}
          >
            <div style={{ width: "100%", overflow: "hidden", height: "398px" }}>
              <iframe
                width="100%"
                height="700"
                loading="lazy"
                style={{ marginTop: "-150px" }}
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC2-toCyYhXyGwn4PBxifmdDT9ZOAjQ2ec&q=${selectedParcel?.propertyAddress ?? ""},${selectedParcel?.propertyCity
                  }+${selectedParcel?.stateName}`}
              ></iframe>
            </div>
          </Card>
        </div>
      </div>
      <br />

      {selectedParcel?.canRequestPayoff === false && (
        <Card className="card-full-width" title="You must contact the county directly for your payoff amount.">
          <h3>Please contact your County Treasurer's Office directly for full payoff information and instructions on how to make your payment.</h3>
        </Card>
      )}

      { }

      {!(selectedParcel?.sheriffSaleDate !== null ||
        selectedParcel?.hasPPS === 1 ||
        selectedParcel?.activePPS === 1 ||
        selectedParcel?.moneyInEscrow !== 0
      )
        && (!(selectedParcel?.sentLegalFile && selectedParcel.noPayoffAfterLegal))
        && (
          <Card className="card-full-width">
            <DataTable
              filterDisplay="row"
              ref={dt}
              value={payoffData}
              onRowClick={($event) => handleRowClick($event)}
              selectionMode="single"
              rowClassName={rowClass}
              stripedRows
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="type"
              header={header}
              globalFilter={globalFilter}
              paginator={false}
              paginatorLeft={paginatorLeft}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              rows={10}
              rowsPerPageOptions={[5, 10, 20, 50]}
              className={"payoff-table p-datatable-sm"}
            >
              <Column expander style={{ width: "40px" }} />
              <Column field="type" header="Type" sortable={false} style={{ width: "120px" }} />
              <Column field="amount" header="Amount" sortable={false} body={priceBodyTemplate} style={{ width: "120px", textAlign: "right" }} />
              {/* <Column field="" header="" sortable={false} style={{ width: "400px" }} /> */}
            </DataTable>
          </Card>
        )}
      <br />
      <br />
      <br />
      <Dialog header="Payment Methods" visible={displayPaymentMethods} style={{ width: "95vw" }} onHide={() => setDisplayPaymentMethods(false)}>
        <div className="p-grid" style={{ marginLeft: "40px", marginRight: "-60px" }}>
          <div className="p-col-3">
            <Card title="Pay Now By Credit Card" style={{ width: "100%" }}>
              <p>You can pay online by credit card for a 3.25% fee.</p>
              <br />
              <table>
                <tr>
                  <td>Total Payoff:</td>
                  <td style={{ textAlign: "right" }}>{formatCurrency(selectedParcel?.payofF_TOTAL ?? 0)}</td>
                </tr>
                <tr>
                  <td>Processing Fee:</td>
                  <td style={{ textAlign: "right" }}>{formatCurrency((selectedParcel?.payofF_TOTAL ?? 0) * 0.0325)}</td>
                </tr>
                <tr style={{ borderTop: "1px solid" }}>
                  <td>Total Charge:</td>
                  <td style={{ textAlign: "right" }}>{formatCurrency((selectedParcel?.payofF_TOTAL ?? 0) + (selectedParcel?.payofF_TOTAL ?? 0) * 0.0325)}</td>
                </tr>
              </table>
              <br />
              <div style={{ width: "100%", textAlign: "center" }}>
                <br />
                <br />
                <form action="api/payments/cc" method="POST" encType="application/x-www-form-urlencoded" onSubmit={() => {
                  console.log(currentUserId);
                }}>
                  <input type="hidden" name="number" value={selectedParcel?.parcelNo} />
                  <input type="hidden" name="amount" value={selectedParcel?.payofF_TOTAL ?? 0} />
                  <input type="hidden" name="id" value={selectedParcel?.parcelID} />
                  <input type="hidden" name="userID" value={currentUserId} />
                  <Button
                    icon={"pi pi-credit-card"}
                    iconPos={"left"}
                    className="p-mr-2  p-button-success"
                    label={`Pay ${formatCurrency((selectedParcel?.payofF_TOTAL ?? 0) + (selectedParcel?.payofF_TOTAL ?? 0) * 0.0325)} by credit card`}
                  />
                </form>
              </div>
            </Card>
          </div>
          {showACH &&
            (<div className="p-col-1">
              <Divider layout="vertical">
                <b>OR</b>
              </Divider>
            </div>)}
          {showACH &&
            (

              <div className="p-col-3">
                <Card title="Pay Now By ACH" style={{ width: "100%" }}>
                  <p>You can pay online by ACH for a 1% fee.</p>
                  <br />
                  <table>
                    <tr>
                      <td>Total Payoff:</td>
                      <td style={{ textAlign: "right" }}>{formatCurrency(selectedParcel?.payofF_TOTAL ?? 0)}</td>
                    </tr>
                    <tr>
                      <td>Processing Fee:</td>
                      <td style={{ textAlign: "right" }}>{formatCurrency((selectedParcel?.payofF_TOTAL ?? 0) * 0.01)}</td>
                    </tr>
                    <tr style={{ borderTop: "1px solid" }}>
                      <td>Total Charge:</td>
                      <td style={{ textAlign: "right" }}>{formatCurrency((selectedParcel?.payofF_TOTAL ?? 0) + (selectedParcel?.payofF_TOTAL ?? 0) * 0.01)}</td>
                    </tr>
                  </table>
                  <br />
                  {currentUser.hasStripeVerifiedACH == true && (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <br />
                      <br />
                      <br />
                      <Button
                        icon={"pi pi-dollar"}
                        iconPos={"left"}
                        className="p-mr-2  p-button-success"
                        label={`Pay ${formatCurrency((selectedParcel?.payofF_TOTAL ?? 0) + (selectedParcel?.payofF_TOTAL ?? 0) * 0.01)} by ACH`}
                        onClick={() => {
                          setIsPartialPayment(false);

                          if (!currentUser.hasStripeVerifiedACH) {
                            if (currentUser.stripeCustomers == undefined) {
                              setDisplayACHRegister(true);
                            } else {
                              setDisplayACHValidate(true);
                            }
                          } else {
                            setDisplayACHPayment(true);
                          }
                        }}
                      />
                    </div>

                  )}
                  {currentUser.stripeCustomers == undefined && currentUser.hasStripeVerifiedACH == false && (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <br />
                      <br />
                      <br />
                      <Button
                        icon={"pi pi-check"}
                        iconPos={"left"}
                        className="p-mr-2  p-button-success"
                        label={`Validate ACH Account`}
                        onClick={() => setDisplayACHValidate(true)}
                      />
                    </div>
                  )}
                  {(currentUser.stripeCustomers === undefined || currentUser.stripeCustomers.length === 0) && (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <br />
                      <br />
                      <br />
                      {/* <Button
                        icon={"pi pi-plus"}
                        iconPos={"left"}
                        className="p-mr-2  p-button-success"
                        label={`Link Your Bank Account`}
                        onClick={() => setDisplayACHRegister(true)}
                    /> */}
                      <Button
                        icon={"pi pi-plus"}
                        iconPos={"left"}
                        className="p-mr-2  p-button-success"
                        label={`Link & Pay Via ACH`}
                        onClick={() => {
                          if (currentUser.id === undefined) {
                            setDisplayLoginMessage(true);
                          } else {
                            open()
                          }
                        }}
                      />
                    </div>
                  )}
                </Card>
              </div>
            )}
          <div className="p-col-1">
            <Divider layout="vertical">
              <b>OR</b>
            </Divider>
          </div>
          <div className="p-col-3">
            {" "}
            <Card title="Mail in Payment" style={{ width: "100%" }}>
              <p>You can mail your full payment for NO additional fee.</p>
              <p>Forms of payment accepted by mail: Cashier's Check or Money Order ONLY.</p>
              <p>Copy of payoff MUST be included with payment when mailed.</p>
              <br />
              <br />
              <div style={{ width: "100%", textAlign: "center" }}>
                <Button
                  icon={"pi pi-envelope"}
                  iconPos={"left"}
                  className="p-mr-2  p-button-success"
                  label={`Print payment instructions`}
                  onClick={() => props.history.push(`/parcels/${selectedParcel?.parcelID}/paymentplanrequestprint`)}
                />
              </div>
            </Card>
          </div>
        </div>
      </Dialog>
      <Dialog header="Payment Methods" visible={displayPaymentPlanMethods} style={{ width: "95vw" }} onHide={() => setDisplayPaymentPlanMethods(false)}>
        <div className="p-grid" style={{ marginLeft: "40px", marginRight: "-60px" }}>
          <div className="p-col-5">
            <Card title="Pay Now By Credit Card" style={{ width: "100%" }}>
              <p>You can pay online by credit card for a 3.25% fee.</p>
              <span>Value: {showACH ? "True" : "False"}</span>
              <br />
              <table>
                <tr>
                  <td>Normal Payment:</td>
                  <td style={{ textAlign: "right" }}>
                    <InputNumber
                      style={{ textAlign: "right" }}
                      inputId="currency-us"
                      value={partialPaymentValue}
                      onChange={(e) => setPartialPaymentValue(e.value ?? 0)}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Processing Fee:</td>
                  <td style={{ textAlign: "right" }}>{formatCurrency(partialPaymentValue * 0.0325)}</td>
                </tr>
                <tr style={{ borderTop: "1px solid" }}>
                  <td>Total Charge:</td>
                  <td style={{ textAlign: "right" }}>{formatCurrency(partialPaymentValue + partialPaymentValue * 0.0325)}</td>
                </tr>
              </table>
              <br />
              <div style={{ width: "100%", textAlign: "center" }}>
                <br />
                <br />
                <form action="api/payments/ccpartial" method="POST" encType="application/x-www-form-urlencoded">
                  <input type="hidden" name="number" value={selectedParcel?.parcelNo} />
                  <input type="hidden" name="amount" value={partialPaymentValue + partialPaymentValue * 0.0325} />
                  <input type="hidden" name="id" value={selectedParcel?.parcelID} />
                  <input type="hidden" name="userID" value={currentUserId} />
                  <Button
                    icon={"pi pi-credit-card"}
                    iconPos={"left"}
                    className="p-mr-2  p-button-success"
                    label={`Pay ${formatCurrency((partialPaymentValue ?? 0) + (partialPaymentValue ?? 0) * 0.0325)} by credit card`}
                  />
                </form>
              </div>
            </Card>
          </div>
          {showACH &&
            (<div className="p-col-1">
              <Divider layout="vertical">
                <b>OR</b>
              </Divider>
            </div>)}
          {showACH &&
            (<div className="p-col-5">
              <Card title="Pay Now By ACH" style={{ width: "100%" }}>
                <p>You can pay online by ACH for a 1% fee.</p>
                <br />
                <table>
                  <tr>
                    <td>Normal Payment:</td>
                    <td style={{ textAlign: "right" }}>
                      <InputNumber
                        style={{ textAlign: "right" }}
                        inputId="currency-us"
                        value={partialPaymentValue}
                        onChange={(e) => setPartialPaymentValue(e.value ?? 0)}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Processing Fee:</td>
                    <td style={{ textAlign: "right" }}>{formatCurrency(partialPaymentValue * 0.01)}</td>
                  </tr>
                  <tr style={{ borderTop: "1px solid" }}>
                    <td>Total Charge:</td>
                    <td style={{ textAlign: "right" }}>{formatCurrency(partialPaymentValue + partialPaymentValue * 0.01)}</td>
                  </tr>
                </table>
                <br />
                <div style={{ width: "100%", textAlign: "center" }}>
                  <br />
                  <br />
                  {currentUser.hasStripeVerifiedACH == true && (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <Button
                        icon={"pi pi-dollar"}
                        iconPos={"left"}
                        className="p-mr-2  p-button-success"
                        label={`Pay ${formatCurrency(partialPaymentValue + partialPaymentValue * 0.01)} by ACH`}
                        onClick={() => {
                          setIsPartialPayment(true);
                          setDisplayACHPayment(true);
                        }}
                      />
                    </div>
                  )}
                  {currentUser.hasStripeVerifiedACH == false && (
                    <Button
                      icon={"pi pi-plus"}
                      iconPos={"left"}
                      className="p-mr-2  p-button-success"
                      label={`Link & Pay Via ACH`}
                      onClick={() => {
                        if (currentUser.id === undefined) {
                          setDisplayLoginMessage(true);
                        } else {
                          open()
                        }
                      }}
                    />
                  )}
                </div>
              </Card>
            </div>
            )}
        </div>
      </Dialog>
      <Dialog header="Set Up Automatic Payments" visible={displayRecurringPayment} style={{ width: "50vw" }} onHide={() => setDisplayRecurringPayment(false)}>
        <div className="p-grid" style={{}}>
          <div className="p-col">
            {(currentUser.stripeCustomers == undefined) && (
              <div style={{ width: "100%", textAlign: "center" }}>
                <br />
                <br />
                <br />
                <Button
                  icon={"pi pi-plus"}
                  iconPos={"left"}
                  className="p-mr-2  p-button-success"
                  label={`Register ACH Account`}
                  onClick={() => setDisplayACHRegister(true)}
                />
              </div>
            )}
            {currentUser.stripeCustomers !== undefined && currentUser.hasStripeVerifiedACH == false && (
              <div style={{ width: "100%", textAlign: "center" }}>
                <br />
                <br />
                <br />
                <Button
                  icon={"pi pi-check"}
                  iconPos={"left"}
                  className="p-mr-2  p-button-success"
                  label={`Validate ACH Account`}
                  onClick={() => setDisplayACHValidate(true)}
                />
              </div>
            )}
            {currentUser.hasStripeVerifiedACH == true && (
              <div style={{ width: "100%", textAlign: "center" }}>
                <br />

                <label>{`Number of Months To Bill ${formatCurrency(partialPaymentValue)}:`}</label>
                <br />
                <InputText
                  name="numberOfMonths"
                  style={{ width: "50px" }}
                  value={numberOfMonths.toString()}
                  onChange={(e) => setNumberOfMonths((e.target as unknown as HTMLTextAreaElement).value as unknown as number)}
                />
                <br />
                <br />
                <Button
                  label="Submit"
                  icon={"pi pi-dollar"}
                  iconPos={"left"}
                  className="p-mr-2  p-button-success"
                  onClick={() => {
                    fetch(`api/payments/chargeAchSubscription`, {
                      method: "post",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                      },
                      body: JSON.stringify({
                        chargeAmount: 0,
                        numberOfMonths: numberOfMonths,
                        parcelId: selectedParcelId,
                      }),
                    })
                      .then((response) => {
                        return response.json() as Promise<any>;
                      })
                      .then((charge) => {
                        setDisplayRecurringPayment(false);
                        props.history.push(`/parcels/${selectedParcelId}/paymentprocesssuccess`);
                      });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Dialog>
      <Dialog header="Please Log In" visible={displayLoginMessage} style={{ width: "50vw" }} onHide={() => setDisplayLoginMessage(false)}>
        <p>You must be logged in to perform this action.</p>
        <p>
          <Button
            className="p-mr-2  p-button-outlined"
            label="Log in"
            icon="pi pi-user"
            onClick={() => {
              props.history.push(`/login`);
            }}
          />
          <Button
            className="p-mr-2  p-button-outlined"
            label="Create an account"
            icon="pi pi-user-plus"
            onClick={() => {
              props.history.push(`/signup`);
            }}
          />
        </p>
      </Dialog>
      <Dialog header="Register Your Bank Account" visible={displayACHRegister} style={{ width: "50vw" }} onHide={() => setDisplayACHRegister(false)}>
        <React.Fragment>
          <div className="p-grid">
            {showACH &&
              (
                <div>
                  <div className="p-col">
                    <Card title="" subTitle="">
                      <label>Routing Number:</label>
                      <br />
                      <InputText
                        name="routing_number"
                        value={routingNumber}
                        onChange={(e) => {
                          setRoutingNumber((e.target as unknown as HTMLTextAreaElement).value);
                        }}
                      />
                      <br />
                      <br />
                      <label>Account Number:</label>
                      <br />
                      <InputText
                        name="account_number"
                        value={accountNumber}
                        onChange={(e) => {
                          setAccountNumber((e.target as unknown as HTMLTextAreaElement).value);
                        }}
                      />
                      <br />
                      <br />
                      <label>Account Holder Name:</label>
                      <br />
                      <InputText
                        name="account_holder_name"
                        value={accountholderName}
                        onChange={(e) => {
                          setAccountholderName((e.target as unknown as HTMLTextAreaElement).value);
                        }}
                      />
                      <br />
                      <br />
                      <label>Account Holder Type:</label>
                      <br />
                      <Dropdown
                        name="account_holder_type"
                        options={accountHolderTypes}
                        placeholder="Select An Account Holder Type"
                        value={accountholderType}
                        onChange={(e) => {
                          setAccountholderType((e.target as unknown as HTMLTextAreaElement).value);
                        }}
                      />
                      <br />
                      <br />

                      <label>Account Type:</label>
                      <br />
                      <Dropdown
                        name="account_type"
                        options={accountTypes}
                        placeholder="Select An Account Type"
                        value={accountType}
                        onChange={(e) => {
                          setAccountType((e.target as unknown as HTMLTextAreaElement).value);
                        }}
                      />
                      <br />
                      <br />
                      <Button
                        label="Submit"
                        onClick={() => {
                          if (stripe != null) {
                            stripe
                              .createToken("bank_account", {
                                country: "US",
                                currency: "usd",
                                routing_number: routingNumber,
                                account_number: accountNumber,
                                account_holder_name: accountholderName,
                                account_holder_type: accountholderType,
                                //account_type: achInfo.account_type,   //it complained when I sent this...
                              })
                              .then(function (tr: TokenResult) {
                                if (tr.error) {
                                  alert(JSON.stringify(tr));
                                } else {
                                  var response = {
                                    last4: accountNumber.slice(accountNumber.length - 4),
                                    token: tr.token,
                                  };
                                  fetch(`api/payments/ach`, {
                                    method: "post",
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                                    },
                                    body: JSON.stringify(response),
                                  })
                                    .then((response) => {
                                      return response.json() as Promise<any>;
                                    })
                                    .then((customer) => {
                                      props.getCurrentUser(currentUserId);
                                      localStorage.setItem("stripeAchCustomer", JSON.stringify(customer));
                                      //window.location.href = "/payment?ach=true";
                                      setDisplayACHRegister(false);
                                      setDisplayACHRegisterSuccess(true);
                                    });
                                }
                              })
                              .catch(function (error: any) {
                                alert(error);
                              });
                          }
                        }}
                      />
                    </Card>
                  </div>
                </div>)}
            <div className="p-col">TEST PLAID
              <button onClick={() => open()} >
                Connect a bank account
              </button></div>
          </div>
        </React.Fragment>
      </Dialog>
      <Dialog header="Registration Success!" visible={displayACHRegisterSuccess} style={{ width: "50vw" }} onHide={() => setDisplayACHRegisterSuccess(false)}>
        <React.Fragment>
          <div className="p-grid">
            <div className="p-col">
              <Card title="" subTitle="">
                Your bank details were submitted successfully. Within the next 1-3 business days our payment processor will deposit two small amounts into your
                account. Once this has happened you can come back and verify your account to complete your ACH payment.
              </Card>
            </div>
          </div>
        </React.Fragment>
      </Dialog>
      <Dialog header="ACH Account Deposit Validation" visible={displayACHValidate} style={{ width: "50vw" }} onHide={() => setDisplayACHValidate(false)}>
        <React.Fragment>
          <div className="p-grid">
            <div className="p-col">
              <Card title="" subTitle="">
                <React.Fragment>
                  <div className="p-grid">
                    <div className="p-col-4"></div>
                    <div className="p-col-3">
                      <label>1st Amount: </label>
                      <br />
                      <InputText
                        name="amount1"
                        value={achValidationAmount1.toString()}
                        onChange={(e) => {
                          setACHValidationAmount1((e.target as unknown as HTMLTextAreaElement).value as unknown as number);
                        }}
                      />
                      <br />
                      <br />

                      <label>2nd Amount: </label>
                      <br />
                      <InputText
                        name="amount2"
                        value={achValidationAmount2.toString()}
                        onChange={(e) => {
                          setACHValidationAmount2((e.target as unknown as HTMLTextAreaElement).value as unknown as number);
                        }}
                      />
                      <br />
                      <br />

                      <Button
                        label="Verfiy"
                        onClick={() => {
                          fetch(`api/payments/verifyAch`, {
                            method: "post",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${localStorage.getItem("token")}`,
                            },
                            body: JSON.stringify({
                              amount1: achValidationAmount1 * 100,
                              amount2: achValidationAmount2 * 100,
                            }),
                          })
                            .then((response) => {
                              return response.json() as Promise<any>;
                            })
                            .then((bankAccount) => {
                              localStorage.setItem("stripeBankAccount", JSON.stringify(bankAccount));
                              setDisplayACHValidate(false);
                              setDisplayACHValidateSuccess(true);
                              props.getCurrentUser(currentUserId);
                            });
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              </Card>
            </div>
          </div>
        </React.Fragment>
      </Dialog>
      <Dialog header="Validation Success!" visible={displayACHValidateSuccess} style={{ width: "50vw" }} onHide={() => setDisplayACHValidateSuccess(false)}>
        <React.Fragment>
          <div className="p-grid">
            <div className="p-col">
              <Card title="" subTitle="">
                Your account was validated successfully. You can now continue on to make your desired payment.
              </Card>
            </div>
          </div>
        </React.Fragment>
      </Dialog>
      <Dialog header={ACHHeader} visible={displayACHPayment} style={{ width: "50vw" }} onHide={() => setDisplayACHPayment(false)}>
        <React.Fragment>
          <div className="p-grid">
            <div className="p-col">
              <Card title="" subTitle="">
                <label>Amount: </label>
                <br />
                <InputText
                  disabled
                  name="chargeAmount"
                  value={
                    isPartialPayment
                      ? formatCurrency((partialPaymentValue ?? 0) + (partialPaymentValue ?? 0) * 0.01)
                      : formatCurrency((selectedParcel?.payofF_TOTAL ?? 0) + (selectedParcel?.payofF_TOTAL ?? 0) * 0.01)
                  }
                />
                <br />
                <br />

                <Button
                  label="Submit Payment"
                  icon={"pi pi-dollar"}
                  iconPos={"left"}
                  className="p-mr-2  p-button-success"
                  onClick={() => {
                    let paymentAmount = 0;
                    let paymentUrl = "";
                    if (isPartialPayment) {
                      paymentAmount = (partialPaymentValue ?? 0) + (partialPaymentValue ?? 0) * 0.01;
                      paymentUrl = `api/payments/chargeAchPartial`;
                    } else {
                      paymentAmount = (selectedParcel?.payofF_TOTAL ?? 0) + (selectedParcel?.payofF_TOTAL ?? 0) * 0.01;
                      paymentUrl = `api/payments/chargeAch`;
                    }
                    fetch(paymentUrl, {
                      method: "post",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                      },
                      body: JSON.stringify({
                        chargeAmount: paymentAmount,
                        parcelId: selectedParcelId,
                        accountId: defaultAchAccountId
                      }),
                    })
                      .then((response) => {
                        return response.json() as Promise<any>;
                      })
                      .then((charge) => {
                        setDisplayACHPayment(false);
                        props.history.push(`/parcels/${selectedParcelId}/paymentprocesssuccess`);
                      });
                  }}
                />
              </Card>
            </div>
          </div>
        </React.Fragment>
      </Dialog>
      <Dialog header="Make an ACH Payment" visible={displayACHPaymentSuccess} style={{ width: "50vw" }} onHide={() => setDisplayACHPaymentSuccess(false)}>
        <React.Fragment>
          <div className="p-grid">
            <div className="p-col">
              <Card title="" subTitle=""></Card>
            </div>
          </div>
        </React.Fragment>
      </Dialog>
      <Dialog
        header={renderHeader()}
        visible={displaySendToOldestUnpaid}
        style={{ width: "50vw" }}
        footer={renderFooter()}
        onHide={() => setDisplaySendToOldestUnpaid(false)}
      >
        <p>
          There is more than one tax lien certificate for this parcel with a balance due. All additional tax liens with an earlier purchase date must be paid.
          <br />
          <br />
          Would you like to review the oldest unpaid tax lien?
        </p>
      </Dialog>
    </React.Fragment>
  );
}

export default connect((state: ApplicationState) => state.parcel, ParcelStore.actionCreators)(ParcelDetailsPayoffRequest as any);

const confirm = () => {
  // confirmDialog({
  //   message: `There is more than one tax lien certificate for this parcel with a balance due. All additional tax liens	with an earlier purchase date must be paid.
  //                 Would you like to review the oldest unpaid tax lien?`,
  //   header: "Notice",
  //   icon: "pi pi-exclamation-triangle",
  //   accept: () => {
  //     setSelectedParcelId(selectedParcel?.oldestRelatedUnpaidCertificateId);
  //     props.getParcel(selectedParcel?.oldestRelatedUnpaidCertificateId ?? 0);
  //     props.history.push(`/parcels/${selectedParcel?.oldestRelatedUnpaidCertificateId}/payoffrequest`);
  //   },
  //   //reject: () => alert("STAY HERE"),
  // });
};

